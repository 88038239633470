

import { useState, useEffect, useRef } from 'react';
import { http } from '../../../helper/http';
import { useLocation } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from "react-toastify";
import { getValueFromObject, formatIndianTime } from "../../../common-function/CommonFunction";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { io } from 'socket.io-client';

interface SensorConfig {
  parameterName: string;
  registerType: string;
  registerAddress: string;
  slaveId: string;
  scalingFactor: string;
  modbusDataType: string;
  dataType: string;
  dataSize: string;
}

const steps = [
  '',
  'Mod Configuration Details updated',
  'Sensor Configuration Details updated',
  'Version Configuration Details updated',
  'Mod configuration done successfully.',
  'Restarting Device',
];

const ModbusConfig = () => {
    const location = useLocation();
    const deviceId = location.state?.deviceId;
    
    const [sensorConfigs, setSensorConfigs] = useState<SensorConfig[]>([]);

    const [baudRate, setBaudRate] = useState('9600');
    const [stopBits, setStopBits] = useState('1');
    const [parityStatus, setParityStatus] = useState('0');
    const [wordLength, setWordLength] = useState('8');
    const [sleepTime, setSleepTime] = useState('15');
    const [firmwareVersionName, setFirmwareVersionName] = useState('Generic');

    const [parameterName, setParameterName] = useState('');
    const [registerType, setRegisterType] = useState('0');
    const [registerAddress, setRegisterAddress] = useState('40001');
    const [slaveId, setSlaveId] = useState('01');
    const [scalingFactor, setScalingFactor] = useState('0');
    const [modbusDataType, setModbusDataType] = useState('1');
    const [dataType, setDataType] = useState('0');
    const [dataSize, setDataSize] = useState('1');

    const [tags, setTags] = useState<any>([]);
    const [deviceName, setDeviceName] = useState('');
    const [currentDevice, setCurrentDevice] = useState<any>([]);
    const [open, setOpen] = useState(false);

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const [currentDeviceLogData, setCurrentDeviceLogData] = useState<any>([]);
    const [buttonText, setButtonText] = useState('Retrieve');
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState<any>();
    
    const baseUrl = process.env.REACT_APP_BASE_URL;

    // const [ModIO, setModIO] = useState(null);
    const modIORef = useRef<any>(null);
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const timeoutRef = useRef<any>(null);

    useEffect(() => {
      if (modIORef.current) {
        modIORef.current.disconnect();
        modIORef.current = null;
      }
      // console.log('disconnect', modIORef.current);
      
      const IO: any = io(`${baseUrl}/modbus-config?deviceId=${deviceId}`);
      modIORef.current = IO;
      // setModIO(IO);
      let waitingForStepThree = false;
      // console.log('reconnect', modIORef.current);
      
      IO.on('retrieve-config-data', (data: any) => {
        clearTimeout(timeoutRef.current);
        if(data){
          setSensorConfigs([]);
          let results = [];
          if(data.deviceCategory == "ESP32"){            
            setBaudRate(data.Modbus_config.buad_rate);
            setStopBits(data.Modbus_config.Stop_bits);
            setParityStatus(data.Modbus_config.parity);
            setWordLength(data.Modbus_config.word_length);

            setSleepTime(data.sleeptime);
            setFirmwareVersionName(data.Firm_Ver);

            const deviceData = data.deviceData;
            const modCount = parseInt(data.Modcount, 10);
            let count = 0;

            for(let key in deviceData){
              if(count < modCount){
                const data = deviceData[key];
                results.push({
                  parameterName: data.Param_Name,
                  registerType: data.Modbus_Reg_Type,
                  registerAddress: data.Reg_Start,
                  slaveId: data.Modbus_Slave_Addr,
                  scalingFactor: data.Scaling_Factor,
                  modbusDataType: data.Modbus_Data_Type,
                  dataType: data.Data_Type,
                  dataSize: data.Data_Size
                });
                count++;
              }
            }       
          } else{
            let parity = '0';
            switch (data.modbusRtu.parity) {
              case 'N':
                parity = '0';
                break;
              case 'O':
                parity = '1';
                break;
              case 'E':
                parity = '2';
                break;
              default:
                parity = '0';
                break;
            }

            setBaudRate(data.modbusRtu.baudRate);
            setStopBits(data.modbusRtu.stopBits);
            setParityStatus(parity);
            setWordLength(data.modbusRtu.dataBits);

            setSleepTime(data.sleeptime);
            setFirmwareVersionName(data.Firm_Ver);

            data.modbusRtu.slaves.forEach((slave: any) => {
              let functionCode = '0';
              switch (slave.functionCode) {
                case '3':
                  functionCode = '0';
                  break;
                case '4':
                  functionCode = '1';
                  break;
                case '1':
                  functionCode = '2';
                  break;
                default:
                  functionCode = '0';
                  break;
              }
              results.push({
                parameterName: slave.regName,
                registerType: functionCode,
                registerAddress: slave.address,
                slaveId: slave.slaveId,
                scalingFactor: slave.Scaling_Factor,
                modbusDataType: '8',
                dataType: slave.modbusDataType,
                dataSize: slave.modbusDataSize,
              });
            })
          }
          setSensorConfigs(results);
          toast.success('Modbus Config Data Retrieved');
          setButtonText('Retrieve');
        }else {
          // clearTimeout(timeout);
          setOpen2(true);
          setButtonText('Retrieve');
        }
      });

      IO.on('config-status', (data: any) => {   
        if(data.success){  
          if (data.step === 2) {
            waitingForStepThree = true;
            setActiveStep(1);
          } else if (data.step === 3 && waitingForStepThree) {
            waitingForStepThree = false;
            setActiveStep(data.step);
          } else if (data.step > 3) {
            waitingForStepThree = false;
            setActiveStep(data.step);
          } else {
            setActiveStep(data.step);
          } 
          if(data.step === 5){
            setTimeout(() => {
              toast.success('Configuration details updated successfully');
              setOpen1(false);
              setActiveStep(0);
              setOpen(false);
            }, 2000);
          }
        }else{
          setOpen2(true);
          setOpen1(false);
          setActiveStep(0);
          setOpen(false);
        }
      });

      return () => {
        if (modIORef.current) {
          modIORef.current.disconnect();
          modIORef.current = null;
        }
      };
    }, [deviceId]);
  
    const fetchTagBasedOnDeviceID = async (deviceId: number) => {
      try { 
        const [response, current_device, current_device_log] = await Promise.all([
          http(`${baseUrl}/tags?deviceId=${deviceId}`),
          http(`${baseUrl}/device?id=${deviceId}`),
          http(`${baseUrl}/device-data-log?deviceId=${deviceId}`)
        ]) as any;
        setCurrentDeviceLogData(current_device_log);
        setTags(response.tags);           
        setCurrentDevice(current_device.devices); 
        setDeviceName(current_device.devices.deviceName);            
      } catch (error) {
        console.error('Error fetching device data:', error);
      }
    };

    useEffect(()=>{
      fetchTagBasedOnDeviceID(deviceId);
    }, [deviceId]);
  
    const handleAddClick = () => {
      if(parameterName &&
        registerType &&
        registerAddress &&
        slaveId &&
        scalingFactor &&
        modbusDataType &&
        dataType &&
        dataSize){
          const sc  = sensorConfigs.find((d: any)=> d.parameterName === parameterName || d.registerAddress === registerAddress);
          if(sc){
            toast.error('Duplicate parameter or register address.')
            return;
          }                    
          if(sensorConfigs.length > 30 && currentDevice.deviceCategory === "ESP32"){
            toast.error('Maximum 30 sensors are allowed.');
            return
          }
          const newConfig: SensorConfig = {
            parameterName,
            registerType,
            registerAddress,
            slaveId,
            scalingFactor,
            modbusDataType,
            dataType,
            dataSize
          };
          setSensorConfigs([...sensorConfigs, newConfig]);
        }else{
          toast.error('All fields are required.');
        }      
    };

    const handleDelete = (index: number) => {
      const newConfigs = [...sensorConfigs];
      newConfigs.splice(index, 1);
      setSensorConfigs(newConfigs);
    }

    const handleEdit = (index: number) => {
      const newConfigs = [...sensorConfigs];
      setEditMode(true);
      setEditIndex(index);
      const data = newConfigs[index];
      setParameterName(data.parameterName);
      setRegisterType(data.registerType);
      setRegisterAddress(data.registerAddress);
      setSlaveId(data.slaveId);
      setScalingFactor(data.scalingFactor);
      setModbusDataType(data.modbusDataType);
      setDataType(data.dataType);
      setDataSize(data.dataSize);
    }

    const handleCancelClick = () => {
      setEditMode(false);
      setParameterName('');
      setRegisterType('0');
      setRegisterAddress('40001');
      setSlaveId('01');
      setScalingFactor('0');
      setModbusDataType('1');
      setDataType('0');
      setDataSize('1');
    }

    const handleUpdateClick = () => {
      sensorConfigs.splice(editIndex, 1);
      if(parameterName &&
        registerType &&
        registerAddress &&
        slaveId &&
        scalingFactor &&
        modbusDataType &&
        dataType &&
        dataSize){
          const sc  = sensorConfigs.find((d: any)=> d.parameterName === parameterName || d.registerAddress === registerAddress);
          if(sc){
            toast.error('Duplicate parameter or register address.')
            return;
          }          
          if(sensorConfigs.length > 30 && currentDevice.deviceCategory === "ESP32"){
            toast.error('Maximum 30 sensors are allowed.');
            return
          }
          const newConfig: SensorConfig = {
            parameterName,
            registerType,
            registerAddress,
            slaveId,
            scalingFactor,
            modbusDataType,
            dataType,
            dataSize
          };
          setSensorConfigs([...sensorConfigs, newConfig]);
          toast.success('Sensor config data updated');
          setEditMode(false);
        }else{
          toast.error('All fields are required.');
        }
    }

    const handleReset = () => {
      setBaudRate('9600');
      setStopBits('1');
      setParityStatus('0');
      setWordLength('8');
      setSleepTime('15');
      setFirmwareVersionName('Generic');
    }

    const handleClickOpen = () => {
      if(!sensorConfigs.length){
        return
      }
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
      setOpen(false)
      setActiveStep(0); 
    };

    const handleClose2 = () => {
      setOpen2(false);
    };

    const handleConfigSubmit = () => {
      if(!sensorConfigs.length){
        return
      }

      setOpen1(true);
      setActiveStep(0); 

      const Modbus_Config = {
          "Update_MOD_Values": {
            "Modbus_config": {
              "buad_rate": baudRate,
              "Stop_bits": stopBits,
              "parity": parityStatus,
              "word_length": wordLength
            }
          }
        }
      ;

      const Version_Details = {
        "Update_MOD_Values": {
          "sleeptime": sleepTime,
          "Firm_Ver": firmwareVersionName
        }
      };

      const Restart_Data = { "restart" : "0" };
      
      // if (!ModIO) {
      //   return;
      // }
      
      modIORef.current.emit('config-data', { Modbus_Config, Version_Details, Device_Data: sensorConfigs, Restart_Data });
    }

    const handleRetrieveConfigData = () => {
      setDataRetrieved(false);
      setButtonText('Retrieving Data...');
      // if (!ModIO) {
      //   return;
      // }      
      
      modIORef.current.emit('retrieve-config-data', { "MODBUS_values" : "0" });
      if(timeoutRef.current){
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        setButtonText('Retrieve');
        setOpen2(true);
      }, 120000);
    }    

  return (
    <>

      <Dialog open={open1} onClose={handleClose1} maxWidth="sm" fullWidth>
        <DialogTitle>
          Modbus Config in Progess
          <IconButton aria-label="close" onClick={handleClose1} sx={{ position: 'absolute', right: 8, top: 8, color: 'inherit' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                label ? (<Step key={label}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div style={{ width: 24, height: 24, borderRadius: '50%', backgroundColor: activeStep >= index ? '#1976d2' : '#bdbdbd' }} />
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
                ) : null
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3, mt: 2 }}>
                <Typography variant="body1">
                  All steps completed - Configuration details updated successfully.
                </Typography>
              </Paper>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <CancelIcon color='warning'/> Failed
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Gateway device communication failed. Verify your connection settings. If the error persists, please consult the user manual or contact support.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <ReportProblemIcon color='warning'/> Caution
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Proceeding with the update will overwrite all existing Modbus configuration details. Please ensure that the new settings are accurate before proceeding, as previous configurations will be permanently replaced.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleConfigSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
      <div className='w-full'>

        <h1 className='mx-6' style={{ fontSize: "28px" }}>Modbus Configurations</h1>

        <div className='mx-6'>

          <div className='mt-4 flex flex-wrap justify-between gap-4'>
            <div className='flex-1 p-2' style={{ maxWidth: '100%' }}>
              <p className='text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                IMEI
              </p>
              <div className='mt-2'>
                <p className='block w-full px-3 py-3 pr-8 leading-tight bg-[#F8FAFC]'>
                  {currentDevice.deviceId}
                </p>
              </div>
            </div>
          
            <div className='flex-1 p-2' style={{ maxWidth: '100%' }}>

              <p className=' text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left'>
                Device Name
              </p>

              <div className='mt-2'>
                <p className='block w-full px-3 py-3 pr-8 leading-tight bg-[#F8FAFC]'>
                  {deviceName || 'device name'}
                </p>
              </div>
            </div>

          </div>

          <div className='flex flex-wrap justify-between items-center gap-4 mt-4'>

            <div className='flex gap-4 mb-3'>
              {/* <img src="/images/Loading.png" className='w-[40px] h-[30px] mt-1' alt="" /> */}
              <span className="font-inter font-medium text-[#393939] text-base md:text-lg lg:text-xl leading-8 md:leading-9 lg:leading-10">
                Last data at {currentDeviceLogData.length ? formatIndianTime(currentDeviceLogData[0]?.timeStamp) : 'N/A'}
              </span>
            </div>
            
            <div className="flex space-x-4 mb-3">
              <div className="relative w-[62] h-[32] top-[12] left-[425] p-2  gap-8 rounded-md flex justify-center items-center border border-gray-300 bg-[blue] cursor-pointer" onClick={handleRetrieveConfigData}>
                <span className='text-white'>{buttonText}</span>
              </div>
              <div className="relative w-[62] h-[32] top-[12] left-[425] p-2  gap-8 rounded-md flex justify-center items-center border border-gray-300 bg-[green] cursor-pointer" onClick={handleClickOpen}>
                <span className='text-white'>Submit Config</span>
              </div>
            </div>

          </div>

          <div className='flex flex-wrap justify-between gap-4'>
            <div className='flex-1 border p-2' style={{ maxWidth: '100%' }}>
              <div className="flex flex-col h-full">
                <div className="conf1 mb-4">
                  <div className="flex justify-between items-center">
                    <div className="p-4 rounded-md">
                      <div className="text-xl font-semibold text-gray-800">
                        Modbus Config
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-gray-300 cursor-pointer" onClick={handleReset}>
                        Reset
                      </div>
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  
                  <div>
                    <p className='text-[#6F7482] text-base'>Baud Rate</p>
                    <input type="text" onChange={(e) => setBaudRate(e.target.value)} value={baudRate} className='w-full px-3 py-2 bg-[#F8FAFC]'/>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Stop Bits</p>
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' onChange={(e) => setStopBits(e.target.value)}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Parity Status</p>
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' onChange={(e) => setParityStatus(e.target.value)}>
                      <option value="0">None (0)</option>
                      <option value="1">Odd(1)</option>
                      <option value="2">Even (2)</option>
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Word Length</p>
                    <input type='text' value={wordLength} onChange={(e) => setWordLength(e.target.value)} className='w-full px-3 py-2 bg-[#F8FAFC]' />
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Sleep Time (Minutes)</p>
                    <input type='text' value={sleepTime} onChange={(e) => setSleepTime(e.target.value)} className='w-full px-3 py-2 bg-[#F8FAFC]' />
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Firmware Version Name</p>
                    <input type='text' onChange={(e) => setFirmwareVersionName(e.target.value)} className='w-full px-3 py-2 bg-[#F8FAFC]' value={firmwareVersionName}/>
                  </div>

                </div>
              </div>
            </div>

            <div className='flex-1 border p-2' style={{ maxWidth: '100%' }}>
              <div className="flex flex-col h-full">
                <div className="conf1 mb-4">
                  <div className="flex justify-between items-center">
                    <div className="p-4 rounded-md">
                      <div className="text-xl font-semibold text-gray-800">
                        Sensor Config
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      {editMode 
                      ? (<><div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-[#0777A7] text-white cursor-pointer" onClick={handleUpdateClick}>
                        <span className='text-white cursor-pointer'>Update</span>
                        </div><div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-black text-white cursor-pointer" onClick={handleCancelClick}>
                        <span className='text-white cursor-pointer'>Cancel</span>
                        </div></>) 
                      : (<div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-black text-white cursor-pointer" onClick={handleAddClick}>
                        <span className='text-white cursor-pointer'>+ Add</span>
                      </div>) }
                    </div>
                  </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                  <div>
                    <p className='text-[#6F7482] text-base'>Parameter Name</p>                
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' value={parameterName} onChange={(e) => setParameterName(e.target.value)}>
                      <option value="">Select parameter name</option>
                      {tags.map((tag: any) => (
                        <option key={tag.Tag.id} value={tag.Tag.tagname}>{tag.Tag.tagname}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Register Type</p>                
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' value={registerType} onChange={(e) => setRegisterType(e.target.value)}>
                      {registerTypeData.map((d: any) => (
                        <option key={d.id} value={d.id}>{d.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Register Address</p>
                    <input type='text' value={registerAddress} onChange={(e) => setRegisterAddress(e.target.value)} className='w-full px-3 py-2 bg-[#F8FAFC]' />
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Slave ID</p>
                    <input type='text' value={slaveId} onChange={(e) => setSlaveId(e.target.value)} className='w-full px-3 py-2 bg-[#F8FAFC]' />
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Scaling Factor</p>
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' value={scalingFactor} onChange={(e) => setScalingFactor(e.target.value)}>
                      {scalingFactorData.map((d: any) => (
                        <option key={d.id} value={d.id}>{d.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Modbus Data Type</p>
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' value={modbusDataType} onChange={(e) => setModbusDataType(e.target.value)}>
                      {modbusDataTypeData.map((d:any)=>(
                        <option key={d.id} value={d.id}>{d.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Data Type</p>
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' value={dataType} onChange={(e) => setDataType(e.target.value)}>
                      {dataTypeData.map((d:any)=>(
                        <option key={d.id} value={d.id}>{d.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <p className='text-[#6F7482] text-base'>Data Size</p>
                    <select className='w-full px-4 py-2 bg-[#F8FAFC]' value={dataSize} onChange={(e) => setDataSize(e.target.value)}>
                      {dataSizeData.map((d: any)=>(
                        <option key={d.id} value={d.id}>{d.name}</option>
                      ))}
                    </select>                    
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className='border mt-5 grid grid-rows-2 justify-items-start gap-4' style={{ overflowX: "auto" }}>
            <div className='w-full '>

              <div className="relative w-full h-[36px] p-2 font-inter text-l font leading-5 tracking-wider text-left text-gray-800">
                Overall Summary
              </div>
              <div className="App">
                <div className='p-2'>
                  <table className='border p-2 w-full' style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                    <tr className='border h-[30px] bg-[#0369a1]'>
                      <th className='border'> <span className='text-white'>Parameter Name</span></th>
                      <th className='border'> <span className='text-white'>Register Type</span></th>
                      <th className='border'> <span className='text-white'>Register Address</span></th>
                      <th className='border'> <span className='text-white'>Slave ID</span></th>
                      <th className='border'> <span className='text-white'>Scaling Factor</span></th>
                      <th className='border'> <span className='text-white'>Modbus Data Type</span></th>
                      <th className='border'> <span className='text-white'>Data Type</span></th>
                      <th className='border'> <span className='text-white'>Data Size</span></th>
                      <th className='border' colSpan={2}> <span className='text-white'>Actions</span></th>
                    </tr>
                    <tbody>
                      {sensorConfigs.map((config: any, index) => (
                        <tr className='font-inter' key={index}>
                          <td className='border'>{config.parameterName}</td>
                          <td className='border'>{getValueFromObject(registerTypeData, config.registerType)}</td>
                          <td className='border'>{config.registerAddress}</td>
                          <td className='border'>{config.slaveId}</td>
                          <td className='border'>{getValueFromObject(scalingFactorData, config.scalingFactor)}</td>
                          <td className='border'>{getValueFromObject(modbusDataTypeData, config.modbusDataType)}</td>
                          <td className='border'>{getValueFromObject(dataTypeData, config.dataType)}</td>
                          <td className='border'>{getValueFromObject(dataSizeData, config.dataSize)}</td>
                          <td className='border'><ClearIcon cursor="pointer" onClick={() => handleDelete(index)}/></td>
                          <td className='border'><EditIcon cursor="pointer" onClick={() => handleEdit(index)}/></td>
                        </tr>
                      ))}

                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

const registerTypeData = [
  {
    'id': 0,
    'name': 'Holding Register'
  },
  {
    'id': 1,
    'name': 'Input Register'
  },
  {
    'id': 2,
    'name': 'Coil Status'
  }
];

const modbusDataTypeData = [
  {
    'id': 1,
    'name': '16 bits signed Integer (AB)'
  },
  {
    'id': 2,
    'name': '16 bits unsigned Integer (AB)'
  },
  {
    'id': 3,
    'name': '32 bits signed Integer (ABCD)'
  },
  {
    'id': 4,
    'name': '32 bits unsigned Integer (ABCD)'
  },
  {
    'id': 5,
    'name': '32 bits Float format (ABCD)'
  },
  {
    'id': 6,
    'name': '32 bits signed Integer (CDAB)'
  },
  {
    'id': 7,
    'name': '32 bits unsigned Integer (CDAB)'
  },
  {
    'id': 8,
    'name': '32 bits Float format (CDAB)'
  }
];

const dataTypeData = [
  {
    'id': 0,
    'name': 'PARAM_TYPE_U8'
  },
  {
    'id': 1,
    'name': 'PARAM_TYPE_U16'
  },
  {
    'id': 2,
    'name': 'PARAM_TYPE_U32'
  },
  {
    'id': 3,
    'name': 'PARAM_TYPE_FLOAT'
  }
];

const dataSizeData = [
  {
    'id': 1,
    'name': 'PARAM_TYPE_U8'
  },
  {
    'id': 2,
    'name': 'PARAM_TYPE_U16'
  },
  {
    'id': 4,
    'name': 'PARAM_TYPE_U32 OR FLOAT'
  }
];

const scalingFactorData = [
  {
    'id': 0,
    'name': '1'
  },
  {
    'id': 1,
    'name': '0.1'
  },
  {
    'id': 2,
    'name': '0.01'
  },
  {
    'id': 3,
    'name': '0.001'
  }
];



export default ModbusConfig