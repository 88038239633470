import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { Checkbox, Dialog, IconButton, CircularProgress } from "@mui/material";
import TabularComponents from "../../../components/deviceComponents/TabularComponents";
import CustomLineChart from "../../../components/deviceComponents/CustomLineChart";
import { http } from "../../../helper/http";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { JwtPayload, jwtDecode } from "jwt-decode";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckBoxFilter from "../../../components/CheckboxField";
import {
  formatIndianTime,
  generateCSV,
  reportData1,
} from "../../../common-function/CommonFunction";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { generateCSV1, reportData } from "../../../common-function/Report";

let header: any = [
  "TimeStamp",
  "Flow",
  "Flow unit",
  // "POSITIVECUMULATIVE",
  // "NEGATIVECUMULATIVE",
  "Total",
  "Total unit",
];

let energy: any = [
  "TimeStamp",
  "AverageVoltage",
  "AverageCurrent",
  "TotalActivePower",
  "TotalReactivePower",
  "TotalApparentPower",
  "Frequency",
  "RPhaseVoltage",
  "YPhaseVoltage",
  "YBPhaseVoltage",
  "BPhaseVoltage",
  "RYPhaseVoltage",
  "RBPhaseVoltage",
  "RPhaseCurrent",
  "YPhaseCurrent",
  "BPhaseCurrent",
  "PowerFactor",
  "RPhasePowerFactor",
  "YPhasePowerFactor",
  "BPhasePowerFactor",
  "RPhaseActivePower",
  "YPhaseActivePower",
  "BPhaseActivePower",
  "RPhaseReactivePower",
  "YPhaseReactivePower",
  "BPhaseReactivePower",
  "RPhaseApparentPower",
  "YPhaseApparentPower",
  "BPhaseApparentPower",
  "ActiveEnergy",
  "ReactiveEnergy",
  "ApparentEnergy",

  "rvtgphthd",
  "yvtgphthd",
  "bvtgphthd",
  "rcrtphthd",
  "ycrtphthd",
  "bcrtphthd",
];

let energyMonitoring: any = [
  "TimeStamp",
  "Active Energy",
  "Reactive Energy",
  "Apparent Energy",
  "Import Active",
  "Import Reactive",
  "Export Active",
  "Export Reactive",
  "Average Neutral",
  "R Phase(V)",
  "Y Phase(V)",
  "B Phase(V)",
  "Average Line-Line",
  "RY Phase(V)",
  "YB Phase(V)",
  "RB Phase(V)",
  "Average Current",
  "R Phase(A)",
  "Y Phase(A)",
  "B Phase(A)",
  "Total Active",
  "R Phase(kW)",
  "Y Phase(kW)",
  "B Phase(kW)",
  "Total Reactive",
  "R Phase(kVAr)",
  "Y Phase(kVAr)",
  "B Phase(kVAr)",
  "Total Apparent",
  "R Phase(kVa)",
  "Y Phase(kVa)",
  "B Phase(kVa)",
  "Average PF",
  "R Phase",
  "Y Phase",
  "B Phase",
  "Frequency",
  "R Phase Volt",
  "Y Phase Volt",
  "B Phase Volt",
  "RY Phase Volt",
  "YB Phase Volt",
  "RB Phase Volt",
  "R Phase Curr",
  "Y Phase Curr",
  "B Phase Curr",
  "Max Active Power",
  "Min Active Power",
  "Max Reactive Power",
  "Min Reactive Power",
  "Max Apparent Power",
  "Run Hours",
  "Current Interrupt",
  "R Phase Volt(max A)",
  "Y Phase Volt(max A)",
  "B Phase Volt(max A)",
  "RY Phase Volt(max A)",
  "YB Phase Volt(max A)",
  "RB Phase Volt(max A)",
  "R Phase Curr(max A)",
  "Y Phase Curr(max A)",
  "B Phase Curr(max A)"
];

const Data = () => {
  // const { devices }: any = useDeviceState();

  const location = useLocation();
  const { deviceId, deviceState }: any = location?.state || {};

  const [template, setTemplate]: any = useState(null);
  const [deviceName, setDeviceName]: any = useState("");
  const [headers, setHeaders]: any = useState<any>([]);
  const [optionColumns, setOptionColumns]: any = useState<any[]>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [apiData1, setApiData1] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  // const levelHeaders = ["TimeStamp", "Level"];
  const [selected, setSelected] = useState<any[]>([]);
  const [filterTable, setFilterTable] = useState(false);
  // const [column, setColumn] = useState<any>(headers);
  const [device, setDevice] = useState<any>(null);

  const [token] = useState(window.localStorage.getItem("token"));
  const [customTag, setCustomTag] = useState<any>({});
  const deviceTemplate = deviceState?.DeviceTemplate;
  useEffect(() => {
    if (deviceTemplate && deviceTemplate.tagJson) {
      const newCustomTag: any = {};
      deviceTemplate.tagJson.forEach((tag: any) => {
        newCustomTag[tag.sensorName] = { ...tag };
      });
      setCustomTag(newCustomTag);
    }
  }, [deviceTemplate]);
  function readRoles(token: any) {
    try {
      const decodedToken: any = jwtDecode<JwtPayload>(token);
      return decodedToken;
    } catch (error) {
      console.error(error);
    }
  }
  const role = readRoles(token);

  // Function to handle click on a single checkbox
  const handleClick = (id: any) => {
    const index = selected.findIndex((item) => item === id);
    setSelected((prevSelected: any) =>
      index === -1
        ? [...prevSelected, id]
        : prevSelected.filter((item: any) => item !== id)
    );
  };

  useEffect(() => {
    fetchDeviceById(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (!device) return;
    // const device: any = devices.devices.find((d: any) => d.id === deviceId);
    setTemplate(device?.template);
    setDeviceName(device?.deviceName);
    const newHeaders = ["#", "TimeStamp"];
    if (device?.template === 5) {
      setHeaders([
        "#",
        "TimeStamp",
        "Flow",
        "Flow unit",
        "Total",
        "Total Unit",
      ]);
    } else if (device?.template === 7) {
      setHeaders(["#", "TimeStamp", "Do", "Ph", "Tds", "Temp"]);
    } else if (
      device?.template === 8 ||
      device?.template === 23 ||
      device?.template === 22
    ) {
      setHeaders(["#", "Timestamp", "X", "Y", "Z"]);
    } else if (device.template === 9) {
      setHeaders(["#", "Timestamp", "Totalizer", "Unit"]);
    } else if (device.template === 10 || device.template === 20) {
      setHeaders(["#", "Timestamp", "Temperature", "Humidity"]);
    } else if (device.template === 12) {
      setHeaders(["#", "TimeStamp", "Ni", "Ph", "Tds", "Temp"]);
    } else if (device.template === 13) {
      setHeaders(["#", "TimeStamp", "Cl", "Ph", "Tds", "Temp", "Turb"]);
    } else if (device.template === 14) {
      setHeaders([
        "#",
        "TimeStamp",
        "AverageVoltage",
        "AverageCurrent",
        "TotalActivePower",
        "TotalReactivePower",
        "TotalApparentPower",
        "Frequency",
        "RPhaseVoltage",
        "YPhaseVoltage",
        "YBPhaseVoltage",
        "BPhaseVoltage",
        "RYPhaseVoltage",
        "RBPhaseVoltage",
        "RPhaseCurrent",
        "YPhaseCurrent",
        "BPhaseCurrent",
        "PowerFactor",
        "RPhasePowerFactor",
        "YPhasePowerFactor",
        "BPhasePowerFactor",
        "RPhaseActivePower",
        "YPhaseActivePower",
        "BPhaseActivePower",
        "RPhaseReactivePower",
        "YPhaseReactivePower",
        "BPhaseReactivePower",
        "RPhaseApparentPower",
        "YPhaseApparentPower",
        "BPhaseApparentPower",
        "ActiveEnergy",
        "ReactiveEnergy",
        "ApparentEnergy",

        "yvtgphthd",
        "bvtgphthd",
        "rcrtphthd",
        "ycrtphthd",
        "bcrtphthd",
      ]);

      // setOptionColumns(enery);
    } else if (device.template === 15) {
      if (deviceTemplate === null || customTag?.Flow?.sensorStatus !== false) {
        newHeaders.push("Flow", "Flow Unit");
      }
      if (
        deviceTemplate === null ||
        customTag?.Totalizer?.sensorStatus !== false
      ) {
        newHeaders.push("Totalizer", "Totalizer Unit");
      }
      setHeaders(newHeaders);
    } else if (device.template === 16) {
      setHeaders(["#", "TimeStamp", "Totalizer", "Flow", "PumpStatus"]);
    } else if (device.template === 17) {
      setHeaders(["#", "TimeStamp", "Ph", "Temp"]);
    } else if (device.template === 18) {
      setHeaders(["#", "TimeStamp", "Tds", "Temp"]);
    } else if (device.template === 19) {
      setHeaders(["#", "TimeStamp", "Cl", "Turb", "Temp"]);
    } else if (device.template === 11) {
      setHeaders(["#", "TimeStamp", "Pump_1", "Pump_2"]);
    } else if (device.template === 21) {
      setHeaders(["#", "TimeStamp", "DeviceStatus"]);
    } else if (device.template === 24) {
      setHeaders([
        "#",
        "TimeStamp",
        "flow",
        "flowunit",
        "totalizer",
        "totalizerunit",
      ]);
    } else if (device.template === 27) {
      setHeaders([
        "#",
        "Timestamp",
        "Flow",
        "Flowrate",
        "Totalizer",
        "Forward Totalizer",
        "Reverse Totalizer",
        "Battery Level",
        "Unit",
      ]);
    } else if (device.template === 25) {
      setHeaders([
        "#",
        "TimeStamp",
        "Energy_Generation",
        "Energy_Consumption",
        "Fuel_Consumed",
        "SFC",
        "Total_Run_Hours",
        "Active_Power",
        "Voltage",
        "Current",
        "Power_Factor",
        "Dg_Controller",
        "Tank_Level",
      ]);
    } else if (device.template === 6) {
      setHeaders(["#", "TimeStamp", "Level"]);
    } else if (device.template === 26) {
      setHeaders([
        "#",
        "TimeStamp",
        "Mppt_Power",
        "Mppt_Voltage",
        "Mppt_Current",
        "Ac_Current",
        "Ac_Voltage",
        "String_Voltage",
        "String_Current",
        "Inverter_Ambient",
        "Temperature_Inverter_Internal",
        "Inverter_Status",
        "Today_Energy",
        "Total_Energy",
        "Active_Power",
        "Power_Factor",
        "Efficiency",
        "CUF",
        "Battery_Level",
        "Pr_Ratio",
        "Frequency",
      ]);
    } else if (device.template === 28) {
      setHeaders(["#", "TimeStamp", "Temperature"]);
    } else if (device.template === 29) {
      setHeaders(["#", "TimeStamp", "Level", "Unit"]);
    } else if (device.template === 31) {
      setHeaders(["#", "TimeStamp", "Totalizer", "Unit"]);
    } else if (device.template === 32) {
      setHeaders(["#", "TimeStamp", "Flow", "FlowUnit", "Total", "TotalUnit"]);
    } else if (device.template === 33 || device.template === 37) {
      if (device.template === 37 && role.role === 1) {
        setHeaders([
          "#",
          "TimeStamp",
          "Flow",
          "FlowUnit",
          "Totalizer",
          "TotalizerUnit",
          "Battery",
          "BatteryUnit",
          "Pulse",
        ]);
      } else {
        setHeaders([
          "#",
          "TimeStamp",
          "Flow",
          "FlowUnit",
          "Totalizer",
          "TotalizerUnit",
          "Battery",
          "BatteryUnit",
        ]);
      }
    } else if (device.template === 34) {
      setHeaders([
        "#",
        "TimeStamp",
        "Flow",
        "FlowUnit",
        "Totalizer",
        "TotalizerUnit",
      ]);
    } else if (device.template === 35) {
      setHeaders(["#", "TimeStamp", "Level", "Unit"]);
    } else if (device.template === 40){
      setHeaders([
        "#",
        "TimeStamp",
        "Active Energy",
        "Reactive Energy",
        "Apparent Energy",
        "Import Active",
        "Import Reactive",
        "Export Active",
        "Export Reactive",
        "Average Neutral",
        "R Phase(V)",
        "Y Phase(V)",
        "B Phase(V)",
        "Average Line-Line",
        "RY Phase(V)",
        "YB Phase(V)",
        "RB Phase(V)",
        "Average Current",
        "R Phase(A)",
        "Y Phase(A)",
        "B Phase(A)",
        "Total Active",
        "R Phase(kW)",
        "Y Phase(kW)",
        "B Phase(kW)",
        "Total Reactive",
        "R Phase(kVAr)",
        "Y Phase(kVAr)",
        "B Phase(kVAr)",
        "Total Apparent",
        "R Phase(kVa)",
        "Y Phase(kVa)",
        "B Phase(kVa)",
        "Average PF",
        "R Phase",
        "Y Phase",
        "B Phase",
        "Frequency",
        "R Phase Volt(%)",
        "Y Phase Volt(%)",
        "B Phase Volt(%)",
        "RY Phase Volt(%)",
        "YB Phase Volt(%)",
        "RB Phase Volt(%)",
        "R Phase Curr(%)",
        "Y Phase Curr(%)",
        "B Phase Curr(%)",
        "Max Active Power",
        "Min Active Power",
        "Max Reactive Power",
        "Min Reactive Power",
        "Max Apparent Power",
        "Run Hours",
        "Current Interrupt",
        "R Phase Volt(max A)",
        "Y Phase Volt(max A)",
        "B Phase Volt(max A)",
        "RY Phase Volt(max A)",
        "YB Phase Volt(max A)",
        "RB Phase Volt(max A)",
        "R Phase Curr(max A)",
        "Y Phase Curr(max A)",
        "B Phase Curr(max A)"
    ])
    }
  }, [device]);

  const fetchDeviceById = async (deviceId: number) => {
    try {
      const current_device = (await http(`/device?id=${deviceId}`)) as any;
      setDevice(current_device.devices);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };

  const chartData = {
    xAxis: [{ data: [1, 2, 3, 5, 8, 10] }],
    series: [{ data: [2, 5.5, 2, 8.5, 1.5, 5] }],
  };

  const [startValue, setStartValue]: any = React.useState<Dayjs | null>(null);
  const [endValue, setEndValue]: any = React.useState<Dayjs | null>(null);
  const [selectedOption, setSelectedOption] = useState<"chart" | "table">(
    "table"
  );

  useEffect(() => {
    if (startValue && endValue) {
      const startDate = startValue.toDate();
      const endDate = endValue.toDate();

      if (!isNaN(startDate) && !isNaN(endDate)) {
        getData({ startDate, endDate, deviceId });
      }
    }
  }, [startValue, endValue, deviceId]);

  async function getData({ startDate, endDate, deviceId }: any) {
    setLoading(true);
    try {
      const resp: any = await http(
        `/device-data?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&deviceId=${deviceId}`
      );
      setApiData1(resp);
      let _data = [];
      if (template === 5) {
        _data = resp.map((d: any, i: any) => {
          if (+d["firmwareVersionId"] !== 1) {
            return {
              logId: d.logId,
              timestamp: formatTimestamp(d.timeStamp),
              // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
              flow: d["Flow"] !== "ERROR" ? d["Flow"] : "ERROR",
              "Flow unit": "m³/hr",
              total:
                d["Forward Total Flow"] !== "ERROR"
                  ? d["Forward Total Flow"]
                  : "ERROR",
              negativecumulative:
                d["Reverse Total Flow"] !== "ERROR"
                  ? d["Reverse Total Flow"]
                  : "ERROR",

              "Total unit": "m³",
              unit: d["m³"],
              rssi: Math.round((+d.rssi / 35) * 110),
            };
          } else {
            return {
              logId: d.logId,

              timestamp: formatTimestamp(d.timeStamp),
              // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
              flow: calculateFlow(resp, i),
              "Flow unit": "m³/hr",
              total:
                d["Forward Total Flow"] !== "ERROR"
                  ? getSanitizedValue(+d["Forward Total Flow"] * 0.001)
                  : "ERROR",
              negativecumulative:
                d["Reverse Total Flow"] !== "ERROR"
                  ? getSanitizedValue(+d["Reverse Total Flow"] * 0.001)
                  : "ERROR",
              // total:
              //   d["Differential Total Flow"] !== "ERROR"
              //     ? getSanitizedValue(+d["Differential Total Flow"] * 0.001)
              //     : "ERROR",
              "Total unit": "m³",
              unit: d["m³"],
              rssi: Math.round((+d.rssi / 35) * 110),
            };
          }
        });
      } else if (template === 6) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            level: d["Piezo Level"],
          };
        });
      } else if (template === 7) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            do: d["DO"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 8 || template === 22 || template === 23) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            x: d["x"],
            y: d["y"],
            z: d["z"],
          };
        });
      } else if (template === 9) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            totalizer: d["Totalizer"],
            unit: "m³",
          };
        });
      } else if (template === 10) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            humidity: d["Humidity"],
          };
        });
      } else if (template === 12) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            ni: d["NI"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 13) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            cl: d["CL"],
            ph: d["PH"],
            tds: d["TDS"],
            temp: d["TEMP"],
            turb: d["TURB"],
          };
        });
      } else if (template === 14) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            averagevoltage: d["avg_vtg"],
            averagecurrent: d["avg_crt"],
            totalactivepower: d["total_act_po"],
            totalreactivepower: d["total_react_po"],
            totalapparentpower: d["total_app_po"],
            frequency: d["frequency"],
            rphasevoltage: d["r_ph_vtg"],
            yphasevoltage: d["y_ph_vtg"],
            ybphasevoltage: d["yb_ph_vtg"],
            bphasevoltage: d["b_ph_vtg"],
            ryphasevoltage: d["ry_ph_vtg"],
            rbphasevoltage: d["rb_ph_vtg"],
            rphasecurrent: d["r_ph_crt"],
            yphasecurrent: d["y_ph_crt"],
            bphasecurrent: d["b_ph_crt"],
            powerfactor: d["power_factor"],
            rphasepowerfactor: d["r_ph_pf"],
            yphasepowerfactor: d["y_ph_pf"],
            bphasepowerfactor: d["b_ph_pf"],
            rphaseactivepower: d["r_ph_act_po"],
            yphaseactivepower: d["y_ph_act_po"],
            bphaseactivepower: d["b_ph_act_po"],
            rphasereactivepower: d["r_ph_react_po"],
            yphasereactivepower: d["y_ph_react_po"],
            bphasereactivepower: d["b_ph_react_po"],
            rphaseapparentpower: d["r_ph_app_po"],
            yphaseapparentpower: d["y_ph_app_po"],
            bphaseapparentpower: d["b_ph_app_po"],
            activeenergy: parseFloat(d["active_energy"]).toFixed(3),
            reactiveenergy: parseFloat(d["reactive_energy"]).toFixed(3),
            apparentenergy: parseFloat(d["apparent_energy"]).toFixed(3),

            rvtgphthd: d["r_vtg_ph_thd"],
            yvtgphthd: d["y_vtg_ph_thd"],
            bvtgphthd: d["b_vtg_ph_thd"],
            rcrtphthd: d["r_crt_ph_thd"],
            ycrtphthd: d["y_crt_ph_thd"],
            bcrtphthd: d["b_crt_ph_thd"],
          };
        });
      } else if (template === 15) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"],
            "Flow unit": deviceTemplate ? customTag.Flow?.sensorUnit : "m³/hr",
            totalizer: d["Totalizer"],
            "Totalizer unit": deviceTemplate
              ? customTag.Totalizer?.sensorUnit
              : "m³",
          };
        });
      } else if (template === 16) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"],
            totalizer: d["Totalizer"],
            pumpstatus: d["Pump Status"],
          };
        });
        // .filter((d: any) => d.flow !== undefined && d.totalizer !== undefined);
      } else if (template === 17) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            ph: d["PH"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 18) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            tds: d["TDS"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 19) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            cl: d["CL"],
            turb: d["TURB"],
            temp: d["TEMP"],
          };
        });
      } else if (template === 11) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            pump_1: d["pump_1"],
            pump_2: d["pump_2"],
          };
        });
      } else if (template === 21) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            devicestatus: d["device_status"],
          };
        });
      } else if (template === 24) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Inst_flow_rate"],
            flowunit: "m³/hr",
            totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
            totalizerunit: "m³",
          };
        });
      } else if (template === 20) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
            humidity: d["Humidity"],
          };
        });
      } else if (template === 27) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            Timestamp: formatTimestamp(d.timeStamp),
            Flow: d["Inst_flow"],
            Flowrate: d["Inst_flow_rate"],
            Totalizer: d["Cumu_total"],
            "Forward Totalizer": d["Pos_Cumu_Int"] + "." + d["Pos_Cumu_Frac"],
            "Reverse Totalizer": d["Rev_Cumu_Dec"],
            "Battery Level": d["Batt_lvl"],
            unit: ["m\u00B3"],
          };
        });
      } else if (template === 25) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            energy_generation: d["ene_gener"],
            energy_consumption: d["ene_cons"],
            fuel_consumed: d["fuel_cons"],
            sfc: d["sfc"],
            total_run_hours: d["total_run_hr"],
            active_power: d["act_po"],
            voltage: d["vtg"],
            current: d["crt"],
            power_factor: d["pf"],
            dg_controller: d["dg_ctrl"],
            tank_level: d["tank_level"],
          };
        });
      } else if (template === 26) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            mppt_power: d["mppt_po"],
            mppt_voltage: d["mppt_vtg"],
            mppt_current: d["mppt_crt"],
            ac_current: d["ac_crt"],
            ac_voltage: d["ac_vtg"],
            string_voltage: d["str_vtg"],
            string_current: d["str_crt"],
            inverter_ambient: d["inver_temp_amb"],
            temperature_inverter_internal: d["inver_temp_intr"],
            inverter_status: d["inver_status"],
            today_energy: d["today_energy"],
            total_energy: d["total_energy"],
            active_power: d["act_po"],
            power_factor: d["pf"],
            efficiency: d["effic"],
            cuf: d["cuf"],
            battery_level: d["bat_level"],
            pr_ratio: d["pr_ratio"],
            frequency: d["frequency"],
          };
        });
      } else if (template === 28) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            temperature: d["Temperature"],
          };
        });
      } else if (template === 29) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            Level: parseFloat(d["Level"]).toFixed(3),
            unit: " cm",
          };
        });
      } else if (template === 31) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            Timestamp: formatTimestamp(d.timeStamp),
            Totalizer: d["Pos_Cumu_flow_Int"] + "." + d["Pos_Cumu_flow_Dec"],
            Unit: "m³",
          };
        });
      } else if (template === 32) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            flow: parseFloat(d["Flow"]),
            flowunit: "m³/hr",
            total: d["Total"],
            totalunit: "m³",
          };
        });
      } else if (template === 33 || template === 37) {
        _data = resp.map((d: any) => {
          if (template === 37 && role.role === 1) {
            return {
              logId: d.logId,
              timestamp: formatTimestamp(d.timeStamp),
              rssi: d["rssi"],
              flow: d["Flow"] || 0,
              flowunit: "m³/hr",
              totalizer: d["Totalizer"] || 0,
              totalizerunit: "m³",
              battery: d["batteryValue"],
              batteryunit: "%",
              pulse: d.pulse ? d.pulse : null,
            };
          } else {
            return {
              logId: d.logId,
              timestamp: formatTimestamp(d.timeStamp),
              rssi: d["rssi"],
              flow: d["Flow"] || 0,
              flowunit: "m³/hr",
              totalizer: d["Totalizer"] || 0,
              totalizerunit: "m³",
              battery: d["batteryValue"],
              batteryunit: "%",
            };
          }
        });
      } else if (template === 34) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"] !== "ERROR" ? d["Flow"] : "ERROR",
            flowunit: "m³/hr",
            totalizer: d["Totalizer"] !== "ERROR" ? d["Totalizer"] : "ERROR",
            totalizerunit: "m³",
            rssi: Math.round((+d.rssi / 35) * 110),
          };
        });
      } else if (template === 35) {
        _data = resp.map((d: any) => {
          return {
            logId: d.logId,
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"],
            Level: parseFloat(d["mass_level"]).toFixed(3),
            unit: ["m"],
          };
        });
      } else if (template === 40) {
        _data = resp.map((d: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),

            // analysis tab
            "Active Energy": d["t_acte"],
            "Reactive Energy": d["t_re"],
            "Apparent Energy": d["t_apte"],
            "Import Active": d["imp_ae"],
            "Import Reactive": d["imp_re"],
            "Export Active": d["exp_ae"],
            "Export Reactive": d["exp_re"],

            // Voltage tab
            "Average Neutral": d["av_ln"],
            "R Phase(V)": d["v1n"],
            "Y Phase(V)": d["v2n"],
            "B Phase(V)": d["v3n"],

            "Average Line-Line": d["av_ll"],
            "RY Phase(V)": d["v12"],
            "YB Phase(V)": d["v23"],
            "RB Phase(V)": d["v31"],

            // current Tab
            "Avarage Current": d["av_c"],
            "R Phase(A)": d["cl1"],
            "Y Phase(A)": d["cl2"],
            "B Phase(A)": d["cl3"],

            // Power Tab
            "Total Active": d["t_kw"],
            "R Phase(kW)": d["kw1"],
            "Y Phase(kW)": d["kw2"],
            "B Phase(kW)": d["kw3"],

            "Total Reactive": d["t_kvar"],
            "R Phase(kVAr)": d["kvar1"],
            "Y Phase(kVAr)": d["kvar2"],
            "B Phase(kVAr)": d["kvar3"],

            "Total Apparent": d["t_kva"],
            "R Phase(kVa)": d["kva1"],
            "Y Phase(kVa)": d["kva2"],
            "B Phase(kVa)": d["kva3"],

            // Power Factor Tab
            "Average PF": d["av_pf"],
            "R Phase": d["pf1"],
            "Y Phase": d["pf2"],
            "B Phase": d["pf3"],

            // Frequency Tab
            Frequency: d["freq"],

            // Treshold Tab
            "R Phase Volt(%)": d["thd_v1n"],
            "Y Phase Volt(%)": d["thd_v2n"],
            "B Phase Volt(%)": d["thd_v3n"],

            "RY Phase Volt(%)": d["thd_v12"],
            "YB Phase Volt(%)": d["thd_v23"],
            "RB Phase Volt(%)": d["thd_v31"],

            "R Phase Curr(%)": d["thd_ci1"],
            "Y Phase Curr(%)": d["thd_ci2"],
            "B Phase Curr(%)": d["thd_ci3"],

            // Demand Tab
            "Max Active Power": d["ap_maxd"],
            "Min Active Power": d["ap_mind"],
            "Max Reactive Power": d["rp_maxd"],
            "Min Reactive Power": d["rp_mind"],
            "Max Apparent Power": d["apr_maxd"],

            // Others Tab
            "Run Hours": d["r_hour"],
            "Current Interrupt": d["a_intrpt"],

            "R Phase Volt(max A)": d["max_iv1n"],
            "Y Phase Volt(max A)": d["max_iv2n"],
            "B Phase Volt(max A)": d["max_iv3n"],

            "RY Phase Volt(max A)": d["max_iv12"],
            "YB Phase Volt(max A)": d["max_iv23"],
            "RB Phase Volt(max A)": d["max_iv31"],

            "R Phase Curr(max A)": d["max_ici1"],
            "Y Phase Curr(max A)": d["max_ici2"],
            "B Phase Curr(max A)": d["max_ici3"],
          };
        });
      }

      if (role.role === 2) {
        _data = _data.filter((d: any) => {
          // Function to check if any value contains "error" (case insensitive)
          const containsError = (value: any) =>
            typeof value === "string" && value.toLowerCase().includes("error");

          // Check if any value in the object contains "error" (case insensitive)
          return !Object.values(d).some(containsError);
        });
      }
      setApiData(_data);
      setLoading(false);
      // console.log("_data", _data);
    } catch (err) {
      console.error(err);
    }
  }

  function formatTimestamp(timestamp: string) {
    return dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");
  }

  function calculateFlow(_data: any, i: any) {
    if (i === _data.length - 1) {
      return getSanitizedValue(0);
    }
    const currentData = _data[i];
    const previousData = _data[i + 1];

    if (
      currentData["Differential Total Flow"] === "ERROR" ||
      previousData["Differential Total Flow"] === "ERROR"
    ) {
      return getSanitizedValue(0);
    }

    const currentFlow =
      currentData["Differential Total Flow"] !== "ERROR"
        ? +currentData["Differential Total Flow"]
        : 0;
    const previousFlow =
      previousData["Differential Total Flow"] !== "ERROR"
        ? +previousData["Differential Total Flow"]
        : 0;
    const timeDiff =
      +new Date(currentData.timeStamp) - +new Date(previousData.timeStamp);
    let flow = ((currentFlow - previousFlow) / timeDiff) * 1000 * 60 * 60;
    if (flow < 0) {
      flow = 0;
    }
    const v = getSanitizedValue(flow * 0.001);
    if (isNaN(v)) {
      return 0;
    }

    return v;
  }

  function getSanitizedValue(value: any) {
    if (typeof value === "number") {
      return value.toFixed(3);
    } else {
      return value;
    }
  }

  let filterData1: any;
  if (role.role === 1) {
    filterData1 = apiData.map((row: any) => ({
      "#": (
        <ProtectedComponent>
          <Checkbox
            color="primary"
            checked={selected.indexOf(row.logId) !== -1}
            onChange={(event) => handleClick(row.logId)}
          />
        </ProtectedComponent>
      ),
      ...row,
    }));
  } else {
    filterData1 = apiData.map((row: any) => ({
      "#": (
        <ProtectedComponent>
          <Checkbox
            color="primary"
            checked={selected.indexOf(row.logId) !== -1}
            onChange={(event) => handleClick(row.logId)}
          />
        </ProtectedComponent>
      ),
      ...row,
    }));
  }
  filterData1.sort(
    (
      a: { timestamp: string | number | Date },
      b: { timestamp: string | number | Date }
    ) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const handleDataLogDelete = async () => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete these logs?"
    );

    if (shouldDelete) {
      try {
        const resp: any = await http(`/device-data-log`, "DELETE", selected);
        if (startValue["$d"] && endValue["$d"]) {
          const startDate: any = new Date(startValue["$d"]);
          const endDate: any = new Date(endValue["$d"]);
          if (startDate !== "Invalid Date" && endDate !== "Invalid Date") {
            getData({ startDate, endDate, deviceId });
          }
        }
        toast(resp.message);
        setSelected([]);
      } catch (error) {
        console.error(error);
      }
    } else {
    }
  };

  const handleClickAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(apiData.map((row: any) => row.logId));
    }
  };

  const toggleFilterList = () => {
    setFilterTable(!filterTable);
  };

  const handleSelectedDataChange = (selectedData: string[]) => {
    setHeaders(selectedData);
    setOptionColumns(selectedData);
  };

  const handleExportReport = () => {
    const startDate: any = new Date(startValue["$d"]);
    const endDate: any = new Date(endValue["$d"]);
    const startDateFormatted = startDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    // Convert end date to desired format
    const endDateFormatted = endDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    let tName = "";
    // console.log("tName", tName);
    switch (device.template) {
      case 5:
      case 6:
      case 9:
      case 15:
      case 16:
      case 24:
      case 31:
      case 32:
      case 33:
      case 34:
      case 37:
        tName += "Water Consumption Summary";
        break;
      case 7:
      case 12:
      case 13:
      case 17:
      case 18:
      case 19:
        tName += "Water Quality Summary";
        break;
      case 8:
      case 22:
      case 23:
        tName += "Vibration Summary";
        break;
      case 10:
      case 20:
        tName += "Temperature & Humadity Summary";
        break;
      case 11:
      case 21:
        tName += "Automation Summary";
        break;
      case 14:
      case 40:
        tName += "Energy Summary";
        break;
      case 27:
        tName += "Atlanta Summary";
        break;
      case 26:
        tName += "Solar Summary";
        break;
      case 25:
        tName += "DG Summary";
        break;
      case 28:
        tName += "Temperature Summary";
        break;
      case 29:
      case 35:
        tName += "Level Summary";
        break;
      case 32:
        tName += "Water Summary";
        break;
      default:
        return;
    }

    if (template === 9) {
      reportData1(
        apiData,
        ["timestamp", "totalizer", "unit"],
        device.deviceName,
        device?.Customer?.name || "N/A",
        tName,
        startDateFormatted + " - " + endDateFormatted
      );
    } else {
      reportData(
        device?.template,
        apiData1,
        device.deviceName,
        device?.Customer?.name || "N/A",
        tName,
        startDateFormatted + " - " + endDateFormatted,
        device,
        deviceTemplate,
        customTag
      );
    }
  };

  const handleExportBarChart = async (exportOption: string) => {
    // const device = devices.devices.find(
    //   (device: any) => device.id === deviceId
    // );
    switch (exportOption) {
      case "Download as PDF":
        handleExportReport();
        break;
      case "Download as CSV":
        if (template === 14) {
          generateCSV(
            optionColumns.length === 0 ? energy : optionColumns,
            apiData
          );
        } else if(template === 40){
          generateCSV(
            optionColumns.length === 0 ? energyMonitoring : optionColumns,
            apiData
          );
        }
         else if (
          template === 5 ||
          template === 26 ||
          template === 25 ||
          template === 9
        ) {
          generateCSV(headers, apiData);
        } else {
          generateCSV1(
            apiData1,
            device.template,
            device,
            deviceTemplate,
            customTag
          );
        }

        break;
      case "Download as Report":
        let column = ["Date", "FirstTimeStamp", "LastTimeStamp", "Consumption"];
        let date = apiData[0].timestamp;
        let lastTimeStamp = apiData[0].timestamp;
        let firstTimeStamp = apiData[apiData.length - 1].timestamp;
        let consumption =
          apiData[apiData.length - 1].totalizer - apiData[0].totalizer;

        if (consumption < 0) {
          consumption = consumption * -1;
        }

        let resultObject = {
          Date: date?.slice(0, 10),
          FirstTimeStamp: firstTimeStamp,
          LastTimeStamp: lastTimeStamp,
          Consumption: consumption,
        };
        reportData1(
          [resultObject],
          column,
          device.deviceName,
          device?.Customer?.name || "N/A",
          "Totalizer Summary",
          formatIndianTime(startValue["$d"]) +
            " - " +
            formatIndianTime(endValue["$d"])
        );
        break;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div className="px-4">
      <div>
        <p className="text-xl font-semibold text-slate-500">{deviceName}</p>
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex">
          <div className="mr-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Start date & time"
                  // value={startValue}
                  onChange={(newStartValue) => setStartValue(newStartValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="End date & time"
                  // value={endValue}
                  onChange={(newEndValue) => setEndValue(newEndValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <ProtectedComponent>
            {selected.length > 0 && (
              <IconButton onClick={handleDataLogDelete}>
                <DeleteIcon />
              </IconButton>
            )}
            {apiData.length > 0 && (
              <IconButton onClick={handleClickAll}>
                <Checkbox checked={selected.length > 0} />
              </IconButton>
            )}
          </ProtectedComponent>
          {(template === 5 || template === 14 || template === 40) && (
            <IconButton onClick={toggleFilterList}>
              <FilterListIcon />
            </IconButton>
          )}

          <LongMenu
            options={[
              "Download as PDF",
              "Download as CSV",
              template === 9 ? "Download as Report" : "",
            ]}
            onOptionClick={handleExportBarChart}
            id={1}
          />
        </div>
      </div>
      <div className="mt-10">
        {selectedOption === "chart" && (
          <div className="graph-list">
            <p className="border-b border-slate-300 pb-2 text-lg text-blue-500">
              Device Name
            </p>
            <div className="flex justify-center mt-4">
              {chartData ? (
                <div>
                  <CustomLineChart {...chartData} height={300} width={500} />
                </div>
              ) : (
                <p>No Data Available</p>
              )}
            </div>
          </div>
        )}
        {selectedOption === "table" && (
          <div className="tabular-data ">
            {loading ? (
              <div className="flex justify-center items-center h-96">
                <CircularProgress />
              </div>
            ) : (
              <TabularComponents
                rows={filterData1}
                headers={headers}
                sortableColumns={["TIMESTAMP"]}
              />
            )}
          </div>
        )}
      </div>
      <Dialog
        open={filterTable}
        onClose={() => {
          setFilterTable(false);
        }}
      >
        <CheckBoxFilter
          data={template === 5 ? header : template === 40 ? energyMonitoring : energy}
          onSelectedDataChange={handleSelectedDataChange}
        />
      </Dialog>
    </div>
  );
};

export default Data;
