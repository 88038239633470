import { useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import axios from "axios";
import {
  filterDataFromEachHourLast24HoursTotalizer,
  formatTimestamp,
  generateCSV1,
  processConsumptionDataTotalizer,
  processHourlyDataTotalizer,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import { IconButton } from "@mui/material";
import LongMenu from "../../utils/longMenu/LongMenu";
import LineChart from "../../components/apex-chart/LineChart";
import flow from "../../images/flow.jpeg";
import totalizer from "../../images/totalizer.jpeg";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import BarChart from "../../components/apex-chart/CommonBarChart1";
import CustomBarChart from "../../components/dashBoardComponents/BarChart2";
const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardTotalLizerFlowrateBattery = ({ devices ,selectedDevice }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const chartRef2 = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(0);

  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");

    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const _data = response.data
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
            rssi: d["rssi"],
            unit: "m³",
            totalizer: d["Totalizer"] || 0,
            flow: d["Flow"] || 0,
            batteryValue: d["batteryValue"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setApiData(_data);

      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);
  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  const flowrate =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, flow }: any) => ({
          timestamp,
          value: flow,
        }))
      : [];
  const battery =
      last24HoursData.length > 0
        ? last24HoursData.map(({ timestamp, batteryValue }: any) => ({
            timestamp,
            value: batteryValue ? batteryValue : "0",
          }))
        : [];

  let consumtionData: any[] | undefined;
  const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24HoursTotalizer(apiData);

  if (filterLast24hourDataOfEachHOur.length > 0) {
    const hourData = processHourlyDataTotalizer(filterLast24hourDataOfEachHOur);
    consumtionData = processConsumptionDataTotalizer(hourData);
  }

  const dummyData: any = [
    {
      name: "Flow",
      color: "#1f79a7",
      data: flowrate,
      postfix: " m³/hr",
    },
  ];

  const batteryData: any = [
    {
      name: "Battery",
      color: "#1f79a7",
      data: battery,
      postfix: " %",
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    // console.log("value 0 clicked");
    
    let filterData = flowrate.map(({ timestamp, value }: any) => {
      return {
        TIMESTAMP: timestamp,
        FLOW_RATE: value,
        UNIT: "m³/hr",
      };
    });
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(filterData);
        break;

      case "Download as PDF":
        if (filterData && chartRef1) {
          reportDataWithGraph(
            chartRef1,
            filterData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Flowrate Summary",
            last24HoursData?.length > 0
              ? last24HoursData[0].timestamp +
                  "-" +
                  last24HoursData[last24HoursData?.length - 1].timestamp
              : ""
          );
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  const handleExportBarChart1 = async (exportOption: string) => {
    // console.log("value 1 clicked");
    
    const filterData =
      consumtionData && consumtionData.length > 0
        ? consumtionData.map(
            ({ hour, firstTimeStamp, lastTimeStamp, consumption }: any) => {
              return {
                hour: hour + ":00",
                firstTimeStamp,
                lastTimeStamp,
                consumption,
              };
            }
          )
        : [];
    switch (exportOption) {
      case "Download as CSV":
        if (filterData) {
          generateCSV1(filterData);
        }
        break;

      case "Download as PDF":
        if (filterData && chartRef) {
          reportDataWithGraph(
            chartRef,
            filterData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Consumption Summary",
            last24HoursData?.length > 0
              ? last24HoursData[0].timestamp +
                  "-" +
                  last24HoursData[last24HoursData?.length - 1].timestamp
              : ""
          );
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  const handleExportBarChart2 = async (exportOption: string) => {
    // console.log("value 3 clicked");
    
    let filterData = battery.map(({ timestamp, value }: any) => {
      return {
        TIMESTAMP: timestamp,
        BATTERY: value ? value : "0",
        UNIT: "%",
      };
    });
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(filterData);
        break;

      case "Download as PDF":
        if (filterData && chartRef2) {
          reportDataWithGraph(
            chartRef2,
            filterData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Consumption Summary",
            last24HoursData?.length > 0
              ? last24HoursData[0].timestamp +
                  "-" +
                  last24HoursData[last24HoursData?.length - 1].timestamp
              : ""
          );
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  const barData = [
    {
      name: "Consumption",
      color: "#2979FF",
      values: consumtionData
        ? consumtionData.map((item) => item.consumption)
        : [],
    },
  ];
  const xAxisCategories = consumtionData
    ? consumtionData.map((item) => item.hour + ":00")
    : [];

    // console.log("selected value", value);
    

  return (
    <div>
      <div className=" p-2 mb-4">
        <section>
          <p className="flex">
            <MobileTowerIcon
              levels={dashboardSts?.rssi || 0}
              time={dashboardSts?.timestamp}
            />
            {"Last Data Received at  " + dashboardSts?.timestamp ||
              "YYYY-MM-DD HH:MM:SS"}
          </p>
        </section>
      </div>
      <div className="border border-gray-400 rounded-md  grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5  p-2">
        <CardTwo
          icon={flow}
          title="Flowrate"
          amount={
            dashboardSts && dashboardSts.flow
              ? `${dashboardSts.flow} m³/hr`
              : "0"
          }
        />
          <CardTwo
            title="Battery"
            amount={
              dashboardSts && dashboardSts.batteryValue
                ? `${dashboardSts.batteryValue}  %`
                : "0 %"
            }
          />
        <CardTwo
          icon={totalizer}
          title="Totalizer"
          amount={
            dashboardSts && dashboardSts.totalizer
              ? `${dashboardSts.totalizer}  m³`
              : "0"
          }
        />
      </div>
      <div className="pt-[5px]"></div>
      <div className="my_temp_humidity">
        <div
          className="border border-gray-400 p-2 rounded-md"
          style={{ height: "100%" }}
        >
          <div className="relative">
            <p>
              {value === 0 ? "Flowrate" : value === 1 ? "Totalizer" : "Battery"} data variation with time
              (Between{" "}
              {last24HoursData.length > 0
                ? last24HoursData[0].timestamp +
                  " to " +
                  last24HoursData[last24HoursData?.length - 1].timestamp
                : " "}
              )
            </p>
            <div className="pb-[20px] pt-[10px]">
              <div className="w-[250px]">
                <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
                  <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                      aria-current="page"
                      style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(0)}
                    >
                      Flowrate
                    </button>
                  </li>
                  <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
                      style={value === 2 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(2)}
                    >
                      Battery
                    </button>
                  </li>
                  <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                      style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(1)}
                    >
                      Totalizer
                    </button>
                  </li>

                </ul>
              </div>
            </div>
            <div className="absolute top-0 right-0">
                <LongMenu
                  options={["Download as CSV", "Download as PDF"]}
                  onOptionClick= {
                    value === 0
                      ? handleExportBarChart
                      : value === 1
                      ? handleExportBarChart1
                      : handleExportBarChart2
                  }
                  id={1}
                />
            </div>
          </div>

          <div>
            {value === 0 ? (
              <>
              <div ref={chartRef1}>
              <LineChart
                stroke={true}
                yAxisName="Flow meter (m³/hr)"
                data={dummyData}
                xAxisName="Time"
                />
                </div>
                </>
            ) : value === 1 ? (
              <>
              <div ref={chartRef}>
              <CustomBarChart
                data={consumtionData}
                yAxisName="Water Consumption"
                xAxisName="Time"
                />
                </div>
                </>
            ) : (
              <>
              <div ref={chartRef2}>
              <LineChart
                stroke={true}
                yAxisName="Battery (%)"
                data={batteryData}
                xAxisName="Time"
                />
                </div>
                </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTotalLizerFlowrateBattery;
