
// export default MeterReadingData;
import React, { useState, useEffect, useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";
import { http } from "../../helper/http";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import {
  downloadExcelReportForMeterReadin,
  downloadPDFMetadata,
} from "../../common-function/Reportgenerate";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";

const baseUrl = process.env.REACT_APP_BASE_URL;

const MeterReadingData = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [apiData, setApiData] = useState<any>(null);
  const [reportFormat, setReportFormat] = useState("excel");
  const [method, setMethod] = useState("manual");
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const { customerId } = useParams();
  const { users } = useUserState();
  const [devices, setDevices] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
  const [showDevices, setShowDevices] = useState(false);
  const [selectAllDevices, setSelectAllDevices] = useState(false);
  const [reportReceivedOn, setReportReceivedOn] = useState<number | null>(null);
  const [receivedTime, setReceivedTime] = useState("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const filtered = devices.filter((device) =>
      device.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDevices(filtered);
  }, [searchTerm, devices]);

  useEffect(() => {
    const fetchDataOverview = async () => {
      try {
        const response1 = (await http(`${baseUrl}/device?alldevice=1`)) as any;
        const devices1 = response1.devices || [];
        const deviceNames1 = devices1
          .map((device: any) => device.deviceName)
          .filter(Boolean);

        const response2 = (await http(
          `${baseUrl}/assign-device?userId=${users.user.id}`
        )) as any;
        const devices2 = Array.isArray(response2.devices)
          ? response2.devices
          : Array.isArray(response2.devices?.devices)
          ? response2.devices.devices
          : [];
        const deviceNames2 = devices2
          .map((device: any) => {
            return device.Device?.deviceName || device.deviceName;
          })
          .filter(Boolean);

        const allDeviceNames = [...deviceNames1, ...deviceNames2];
        const uniqueDeviceNames = Array.from(new Set(allDeviceNames));
        setDevices(uniqueDeviceNames);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataOverview();
  }, [users.user.id]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDevices(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData(startDate.toDate(), endDate.toDate());
    }
  }, [startDate, endDate]);
  // console.log("apiData", apiData);

  const fetchData = async (startD: Date, endD: Date) => {
    try {
      const response = (await http(
        `${baseUrl}/report?startDate=${startD.toISOString()}&endDate=${endD.toISOString()}&customerId=${customerId}`
      )) as {
        deviceName: string;
        data: { status: number; data: any[] };
        template: number;
      }[];
      // console.log("response", response);
      setApiData(response);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  };

  const handleToggleDevices = () => {
    setShowDevices(!showDevices);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceivedTime(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(emailRegex.test(email) ? null : "Invalid email address");
  };

  const handleDeviceSelection = (device: string) => {
    if (selectedDevices.includes(device)) {
      setSelectedDevices(selectedDevices.filter((d) => d !== device));
    } else {
      setSelectedDevices([...selectedDevices, device]);
    }
  };

  const handleSelectAllDevices = (checked: boolean) => {
    if (checked) {
      setSelectedDevices(devices);
    } else {
      setSelectedDevices([]);
    }
    setSelectAllDevices(checked);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setApiData(null);
    setReportFormat("excel");
    setMethod("manual");
    setSelectedDevices([]);
  };

  const handleClick = () => {
    if (selectedDevices.length === 0) {
      toast.error("Please select at least one device");
      return;
    }

    if (reportFormat === "excel") {
      downloadExcelReportForMeterReadin(
        selectedDevices,
        apiData,
        startDate,
        endDate,
        users
      );
    } else if (reportFormat === "PDF") {
      downloadPDFMetadata(selectedDevices, apiData, startDate, endDate, users);
    }
  };

  // console.log("recied timw", email);

  return (
    <div className="px-4 mt-6">
      <div className="border w-[562px] px-2 py-4 rounded-md">
        <div className="font-inter p-2 text-xl font-bold leading-5 tracking-wider text-left text-gray-800">
          Meter Reading Data Report Config
        </div>
        <div className="mx-2 mt-2">
          <div className="grid grid-cols-3 justify-items-start mt-4 gap-4">
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Report Format
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
                  value={reportFormat}
                  onChange={(e) => setReportFormat(e.target.value)}
                >
                  <option value="selectReportFormat">
                    Select Report Format
                  </option>
                  <option value="excel">Excel</option>
                  <option value="PDF">PDF</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Method
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                >
                  <option value="manual">Manual</option>
                  <option value="autogenerated">Auto</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Report Received On
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC]"
                  value={reportReceivedOn ?? ""}
                  onChange={(e) =>
                    setReportReceivedOn(parseInt(e.target.value))
                  }
                >
                  <option value={1}>Monday</option>
                  <option value={2}>Tuesday</option>
                  <option value={3}>Wednesday</option>
                  <option value={4}>Thursday</option>
                  <option value={5}>Friday</option>
                  <option value={6}>Saturday</option>
                  <option value={7}>Sunday</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <label
                className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                htmlFor="r-time"
              >
                Received Time
              </label>
              <TextField
                type="time"
                id="r-time"
                name="receivedTime"
                value={receivedTime}
                onChange={handleTimeChange}
                variant="outlined"
                className="border border-gray-300 rounded-md p-[0.4rem] w-full"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="w-full col-span-2">
              <div>
                <label
                  className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="border border-gray-300 rounded-md p-[0.4rem] w-full"
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">{emailError}</p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
                <div className="w-full">
                  <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                    Start Date
                  </p>
                  <DateTimePicker
                    value={startDate}
                    onChange={(e: any) => setStartDate(e)}
                    className="w-[100%] bg-[#F8FAFC] "
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                    End Date
                  </p>
                  <DateTimePicker
                    value={endDate}
                    onChange={(e: any) => setEndDate(e)}
                    className="w-[100%] bg-[#F8FAFC] mt-2"
                  />
                </div>
              </div>
            </LocalizationProvider>
          </div>

          <div className="w-full mt-4" ref={dropdownRef}>
            <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
              Select Devices
            </p>
            <div className="mt-2">
              <div className="relative">
                <button
                  className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 font-semibold py-4 px-4 w-full text-left"
                  onClick={handleToggleDevices}
                >
                  {selectedDevices.length > 0
                    ? selectedDevices.join(", ")
                    : "Select Devices"}
                </button>
                {showDevices && (
                  <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                    <div className="sticky top-0 bg-white p-2">
                      <input
                        type="text"
                        placeholder="Search devices..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <ul>
                      <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
                        <input
                          type="checkbox"
                          checked={selectAllDevices}
                          onChange={(e) =>
                            handleSelectAllDevices(e.target.checked)
                          }
                        />
                        <span className="ml-2">Select All Devices</span>
                      </li>
                      {filteredDevices.map(
                        (deviceName: string, index: number) => (
                          <li
                            key={index}
                            className="py-2 px-4 hover:bg-gray-100"
                          >
                            <input
                              type="checkbox"
                              checked={selectedDevices.includes(deviceName)}
                              onChange={() => handleDeviceSelection(deviceName)}
                            />
                            <span className="ml-2">{deviceName}</span>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-5 gap-4">
            <div
              className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
              onClick={handleReset}
            >
              <span className="text-black px-2">Reset</span>
            </div>
            <div
              className="relative p-2 gap-8 rounded-md bg-[green] cursor-pointer"
              onClick={handleClick}
            >
              <span className="text-white">Submit</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterReadingData;
