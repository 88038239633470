

import React, { useState } from "react";
import Checkbox from '@mui/material/Checkbox';

interface CheckboxFieldProps {
  label: string;
  checked: boolean;
  onChange: () => void;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ label, checked, onChange }) => (
  <label className="flex items-center space-x-2 cursor-pointer">
    <Checkbox
      checked={checked}
      onChange={onChange}
      
      sx={{ color: '#2563EB' }} // Customize the checkbox color
    />
    <span className="text-gray-800">{label}</span>
  </label>
);

interface AppProps {
  data: string[];
  onSelectedDataChange: (selectedData: string[]) => void;
}

const CheckBoxFilter: React.FC<AppProps> = ({ data, onSelectedDataChange }) => {
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (header: string) => {
    setSelectedHeaders((prevSelected) =>
      prevSelected.includes(header)
        ? prevSelected.filter((selectedHeader) => selectedHeader !== header)
        : [...prevSelected, header]
    );
    onSelectedDataChange([...selectedHeaders, header]); // Update parent with selected headers
  };

  const handleSelectAllChange = () => {
    const allHeaders = selectAll ? [] : data;
    setSelectedHeaders(allHeaders);
    setSelectAll(!selectAll);
    onSelectedDataChange(allHeaders); // Update parent with selected headers
  };

  return (
    <div className="bg-gray-100 p-4 rounded-md">
      <h2 className="m-2 font-bold text-gray-800">Select Data</h2>
      <form className="flex flex-col items-start space-y-2 p-2">
        <CheckboxField
          label="Select All"
          checked={selectAll}
          onChange={handleSelectAllChange}
        />
        {data.map((header) => (
          <CheckboxField
            key={header}
            label={header}
            checked={selectedHeaders.includes(header)}
            onChange={() => handleCheckboxChange(header)}
          />
        ))}
      </form>
    </div>
  );
};

export default CheckBoxFilter;
