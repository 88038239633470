import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  addNewDevices,
  updateDevices,
} from "../../../app-context/device-context/DeviceAction";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { Device, DeviceApiResponse } from "../../../types/DeviceTypes";
import { http } from "../../../helper/http";
import { toast } from "react-toastify";
import { Template } from "../../../types/TemplateType";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { Customer } from "../../../types/CustomerTypes";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface AddDeviceFormModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: Device[];
  uniqueId: string;
}

let id: number | 0;

const AddDeviceFormModal: React.FC<AddDeviceFormModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [template, setTemplate] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [customer, setCustomer] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [checked, setChecked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCustomers, setShowCustomers] = useState(false);
  const { devices, dispatch } = useDeviceState();
  const { templates } = useTemplateState();
  const { customers, dispatch: customerDispatch } = useCustomerState();
  const [customTag, setCustomTag] = useState(false);
  const [configBatteryStatus, setConfigBatteryStatus] = useState(false);
  const [selectedDeviceCategory, setSelectedDeviceCategory] = useState("ESP32");
  const [selectedSlaveId, setSelectedSlaveID] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCustomer(customers.customers);
    setTemplate(templates.templates);
  }, [customers.customers, templates.templates]);

  useEffect(() => {
    if (uniqueId === "EDIT" && data) {
      const deviceToUse = Array.isArray(data) ? data[0] : data;

      id = deviceToUse.id;
      setDeviceId(deviceToUse.deviceId);
      setDeviceName(deviceToUse.deviceName || "");
      const customerData = customers.customers.find(
        (c) => c.id === (deviceToUse?.customerId || 0)
      );
      const templateData = templates.templates.find(
        (t) => t.id === deviceToUse.template
      );

      setSelectedTemplate(templateData?.id?.toString() || "");
      setSelectedCustomer(customerData?.id.toString() || "");
      setLatitude(deviceToUse?.latitude.toString() || "");
      setLongitude(deviceToUse?.longitude.toString() || "");
      setChecked(deviceToUse.modbusConfig);
      setCustomTag(deviceToUse.customTag);
      setSelectedDeviceCategory(deviceToUse.deviceCategory || "ESP32");
      setConfigBatteryStatus(deviceToUse.batteryConfigStatus || false);
      setSelectedSlaveID(deviceToUse.slaveId || null);
    }
  }, [uniqueId, data, customers.customers, templates.templates]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowCustomers(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleCustomTagChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomTag(event.target.value === "true");
  };

  const handleBatteryStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfigBatteryStatus(event.target.value === "true");
  };

  const handleSave = async () => {
    const data = {
      deviceId,
      deviceName,
      template: selectedTemplate,
      longitude,
      latitude,
      templateid: selectedTemplate,
      customerId: selectedCustomer,
      modbusConfig: checked,
      customTag: customTag,
      deviceCategory: selectedDeviceCategory,
      batteryConfigStatus: configBatteryStatus,
      slaveId: selectedDeviceCategory === "ESP32" ? null : selectedSlaveId,
    };
    try {
      if (uniqueId === "ADD") {
        const resp = (await http(
          baseUrl + `/device`,
          "POST",
          data
        )) as DeviceApiResponse;
        addNewDevices(dispatch, {
          devices: resp.devices,
          addTotalItems: devices.totalItems,
        });
        toast.success(resp.message);
      } else {
        const resp = (await http(
          baseUrl + `/device?id=${id}`,
          "PUT",
          data
        )) as DeviceApiResponse;
        updateDevices(dispatch, { id, devices: resp.devices });
        toast.success(resp.message);
      }
    } catch (err: any) {
      toast.error(err);
    }
    toggleFormFun();
  };

  const filteredCustomers = customer.filter((c) =>
    c.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={toggleForm} onClose={toggleFormFun}>
      <DialogTitle>
        {uniqueId === "ADD" ? "Add Device" : "Update Device"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="IMEI No"
          placeholder="Enter The Device Id"
          fullWidth
          margin="normal"
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
          required
        />
        <TextField
          label="Device Name"
          placeholder="Enter"
          fullWidth
          margin="normal"
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
        />
        <ProtectedComponent>
          <InputLabel id="selectcustomer">Select the Customer</InputLabel>
          <div ref={dropdownRef}>
            <TextField
              fullWidth
              value={
                selectedCustomer
                  ? customer.find((c) => c.id.toString() === selectedCustomer)
                    ?.name
                  : ""
              }
              onClick={() => setShowCustomers(!showCustomers)}
              placeholder="Select Customer"
              InputProps={{
                readOnly: true,
              }}
            />
            {showCustomers && (
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <TextField
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search customers..."
                />
                {filteredCustomers.map((c) => (
                  <MenuItem
                    key={c.id}
                    value={c.id}
                    onClick={() => {
                      setSelectedCustomer(c.id.toString());
                      setShowCustomers(false);
                    }}
                    style={{
                      paddingLeft: "20px",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    {c.name}
                  </MenuItem>
                ))}
              </div>
            )}
          </div>
        </ProtectedComponent>

        <InputLabel id="selectDeviceCategory">Device Category</InputLabel>
        <Select
          labelId="selectDeviceCategory"
          id="select-device-category"
          value={selectedDeviceCategory}
          label="Device Category"
          onChange={(e) => setSelectedDeviceCategory(e.target.value)}
          fullWidth
        >
          <MenuItem value="ESP32">ESP32</MenuItem>
          <MenuItem value="F6">F6</MenuItem>
        </Select>


        <InputLabel id="selectunit">Select the Template</InputLabel>
        <Select
          labelId="selecttemplate"
          id="select-template"
          value={selectedTemplate}
          label="Select Unit"
          onChange={(e) => setSelectedTemplate(e.target.value)}
          fullWidth
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
        >
          {template.map((template) => (
            <MenuItem
              key={template.id}
              value={template.id}
              style={{
                paddingLeft: "20px",
                display: "block",
                marginBottom: "5px",
              }}
            >
              {template.templatename}
            </MenuItem>
          ))}
        </Select>

        {selectedDeviceCategory === "F6" && (
          <>
            <InputLabel id="selectSlaveID">Slave ID</InputLabel>
            <Select
              labelId="selectSlaveID"
              id="select-slave-id"
              value={selectedSlaveId}
              label="Slave ID"
              onChange={(e) => setSelectedSlaveID(e.target.value)}
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust the maxHeight value as needed
                  },
                },
              }}
            >
              {Array.from({ length: 32 }, (_, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <div className="flex flex-col md:flex-row md:gap-4">
          <TextField
            label="Latitude"
            placeholder="Enter"
            fullWidth
            margin="normal"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
          />
          <TextField
            label="Longitude"
            placeholder="Enter"
            fullWidth
            margin="normal"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Config Battery Status</FormLabel>
            <RadioGroup
              row
              value={configBatteryStatus}
              onChange={handleBatteryStatusChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Custom Tag</FormLabel>
            <RadioGroup row value={customTag} onChange={handleCustomTagChange}>
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Checkbox checked={checked} onChange={handleCheckboxChange} /> modbus
        config
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={toggleFormFun}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          {uniqueId === "ADD" ? "Save" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeviceFormModal;
